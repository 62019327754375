import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import messages from './mslc-lang.json';
export default {
	i18n: {
		messages
	},
	data() {
		const vm = this;
		return {
			slidesPerView: 0,
			slidesLength: 0,
			swiperOptions: {
				a11y: {
					prevSlideMessage: this.$t('previousPage'),
					nextSlideMessage: this.$t('nextPage'),
					firstSlideMessage: this.$t('firstPage'),
					lastSlideMessage: this.$t('lastPage'),
					paginationBulletMessage: this.$t('jumpToPage')
				},
				slidesPerView: 1,
				spaceBetween: 16,
				breakpoints: {
					768: {
						slidesPerView: 3,
						spaceBetween: 16
					},
					996: {
						slidesPerView: 3,
						spaceBetween: 32
					}
				},
				pagination: {
					el: '.mslc__pagination',
					clickable: true
				},
				navigation: {
					nextEl: '.mslc__button-next',
					prevEl: '.mslc__button-prev'
				},
				on: {
					breakpoint({ slidesPerView }) {
						vm.slidesPerView = slidesPerView;
					},
					init() {
						vm.slidesLength = this.slides.length;
					}
				}
			}
		};
	},
	components: {
		Swiper,
		SwiperSlide
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		},
		hasPager() {
			return this.slidesLength > this.slidesPerView;
		}
	},
	// reinitialize swiper when it becomes visible
	mounted() {
		// Function to check visibility by traversing ancestors
		const checkVisibility = () => {
			let current = this.$el;
			while (current) {
				const style = window.getComputedStyle(current);
				if (style.display === 'none') {
					return;
				}
				current = current.parentElement;
			}
			this.$refs.swiper.initSwiper();
		};
		// Observe ancestors
		const observerList = [];
		let current = this.$el;
		while (current) {
			const observer = new MutationObserver(() => {
				checkVisibility();
			});
			observer.observe(current, {
				attributes: true,
				attributeFilter: ['style', 'class'] // Observe style and class changes
			});
			observerList.push(observer);
			current = current.parentElement;
		}
		// cleanup
		this.$once('hook:beforeDestroy', () => {
			observerList.forEach((observer) => observer.disconnect());
		});
	}
};
